@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$darkBlue: #1cb3d3;
$lightBlue: #e5f7fb;
$lighterBlue: #87cfdf;
$bodyTextColor: #7d7d7d; 

$fontSizeXS: 8px;
$fontSizeS: 12px;
$fontSizeM: 15px;
$fontSizeL: 19px;

$largeLineHeight: 28px;

$contentBackgroundColor: white;
.spacer-small{
  margin-top: 15px;
}

.spacer{
  margin-top: 25px;
}
.spacer-large{
  margin-top: 40px;
}
.spacer-xlarge{
  margin-top: 80px;
}

.margin10px{
  margin:  10px;
}
.margin5px{
  margin:  5px;
}
.margin15px{
  margin:  15px;
}

.font-small{
  font-size: $fontSizeM;
}

.round-image{
  border-radius: 50%;
}

.breadcrumb{
  background-color:transparent;
}
.float-left{
  float:left;
}

.nodisplay{
  display:none;
}
.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

#app-layout input{
  border-radius: 0px;
}

#app-layout textarea{
  border-radius: 0px;
}

#app-layout .btn{
   border-radius: 0px;
}

#app-layout .clear-both{
  clear:  both;
}
#app-layout .panel-default{
  border: 0px;
  border-radius: 0px;

  .panel-heading{
    padding: 15px 15px;
    line-height: $largeLineHeight;
    border: 0px;
    font-size: $fontSizeL;
    border-radius: 0px;
    color: white;
    background-color: $darkBlue;
  }

  .panel-body{
    border: 0px;
    border-radius: 0px;
    color: $darkBlue;
    background-color: $lightBlue;
  }
}

#app-layout .sidebar-nav .navbar-default{
  border: 0px;
  border-radius: 0px;
  background-color: $darkBlue;
  ul{
    margin: 0px;
  }

  .menu_icon{
    float:right;
  }

  li a{
    line-height: $largeLineHeight;
    font-size: $fontSizeL;
    color: white;
    padding: 0px;
  }
  li{
    padding: 15px 20px 15px 20px;
    border-top: 2px solid $lighterBlue;
  }

  li:first-child { border-top: none; }

  .active a{
      font-weight: 800;
      background-color: transparent;
  }

  .website-menu{
    background-color: $lightBlue;
    box-sizing:border-box;


    li:first-child { border-top: none; }
    li{

      .modify-menu-link{
        display:none;
      }

      border-top: 2px solid white;
      font-size: $fontSizeM;
      box-sizing: border-box;

      a{
        color: #28b5d4;
      }
      .unpublished{
        color:  #84cedf;
      }
      

      a:hover{
        text-decoration: none;
      }

      div:hover .modify-menu-link{
        display:inline-block;
      }

      ul{
        list-style-type: none;
        padding-left: 30px;
        li{
          border: none;
          padding: 10px 0px;
        }

        li {

          a{
            padding: 0px 15px;
            padding-right: 0px;
            font-weight: normal;
            border-left: 2px solid $darkBlue;
          }
          &.active a {
            font-weight: 800;
          }

        }
       
      }
        .hidden-list-item{
          padding-top: 0px;
          padding-bottom: 0px;
        }
    }

    .add-sub-page-control{
      margin-left: 30px;
      padding: 10px 0px;
     
      a{
        border-left: 2px solid $darkBlue;
      }

      img{
        margin-left: 15px;
      }
    }
  }
}


/* Template admin page */
.template-editor{
  width: 100%;
  margin-top: 20px;
  min-height: 600px;
  border: 1px solid #ebebeb;
}

.tab-content{
  overflow: auto;
}


.nav-tabs li .active a{
  background-color: $lightBlue;
}

/* end admin template page */

.sidebar-nav .navbar .navbar-collapse {
  padding: 0;
  max-height: none;
}

/*
Content page
*/

.module .alert{
  margin:  10px;
}
.module .success-message-box{
  margin: 10px;
  display: none;
}
.module .error-message-box{
  margin:10px;
  display:none;
}
.module .progress-save{
  display: none;
  margin:  10px;
}

.module .publish-notice{
  font-size:  $fontSizeM;
}

.page-heading{
  background-color:  white;
  font-size:  $fontSizeL;
  line-height:  $largeLineHeight;
  padding:  10px;
  margin:  10px;
  color: $bodyTextColor;
  position: relative;

  .page-controls{
    position: absolute;
    right: 0px;
    top:  0px;
    font-size:  $fontSizeM;
    height:  100%;
    div{
      padding: 10px;
      float: left;
      color:  white;
      margin-left:  3px;
      cursor:  pointer;
    }
    .page-save{
        background-color: #31bd00;
    }
    .page-remove{
        background-color: #dd0000;
    }
    .page-settings{
      background-color:  $darkBlue;
    }
  }
}

.page-content{
  background-color:  white;
  padding:  10px;
  margin:  10px;
  margin-top: 15px;
  color: $bodyTextColor;
  overflow: auto;
  overflow:visible;
  display:inline-block;
  width: calc(100% - 20px);
}

.subtitle{
  color: $darkBlue;
  font-weight:  600;
  font-size: 19px;
}


#app-layout .form-settings  .control-label{
  text-align: left;  
}

.form-settings label {
  font-size: $fontSizeM;
}
/* end content page */

/* image gallery */

.gallery-overview{
  box-sizing:  border-box;

  .gallery-item{
    float:  left;
    margin:  10px;
    position: relative;

    input{

      width:  300px;
      box-sizing: border-box;
      margin-top:  5px;
      clear: both;
      float: left;
    }
    .gallery-thumb{
      width:  300px;
      clear:  both;
      float:  left;
      cursor: move;
    }

    .image-remove{
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: #7d7d7d;
      padding: 0px 2px 2px 2px;
    }

  }
}


.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

.thumb {
  height: 75px;
  margin: 10px 5px 0 0;
}
.btn-add-to-album{
  display: none;
  clear: both;
}

 .preview-thumb{
  position: relative;
  float:  left;
  display: inline-block;
 }

.remove-preview-thumb{
  position: absolute;
  background-color:  #7d7d7d;
  top: 10px;
  right: 5px;
  padding: 0px 2px 2px 2px;
  cursor: pointer;
}

/* end image gallery */

/* begin news admin */

.newsarticle{
  float: left;
  margin: 10px 0px;
  width: 100%;
  clear:both;
  padding: 25px;
  box-sizing: border-box;
  margin-bottom:25px;
  background-color: $lightBlue;

  .image-thumb{
    width: 300px;
    height: 225px;
    overflow:hidden;
    position:relative;
    float: left;
    margin-right: 20px;

    img{
      width: 100%;
    }
  }
  .body{
    .title{
      font-size: $fontSizeL;
    }
    .publication{
      font-style: italic;
      margin: 5px 0px;
    }

  }
}

/* end news admin */


/* begin profiles admin */

.profile-thumb-300{
    width: 300px;
    height: 300px;
    overflow:hidden;
    position:relative;
    float: left;
    margin-right: 20px;

    img{
      width: 100%;
    }
}

/* end profiles admin */

/* begin  calendar admin */
.calendar{
  .calendar-header{
    th{
      text-align: center;
      font-size: $fontSizeL;
    }
  }
  tbody{
    tr{
      td{
        height: 120px;
        width: calc(100% / 7);
      }
    }
  }
  .cal_labels{
    td{
      height: 40px;
      line-height: 30px;
    }
  }
  .calendar-day:hover{
    background-color: $lightBlue;
    cursor: pointer;
  }
}
/* end calendar admin */

/* begin blogs */
.page-content .blogs{
  .blog{
    width: 175px;
    height:175px;
    background-color: $lightBlue;
    display:inline-block;
    margin: 10px 10px 10px 0px;
    text-align:center;
    box-sizing:border-box;
    position:relative;
    display: table;
    float:left;
    background-repeat:no-repeat;
    background-position: center center;
    cursor: pointer;
    background-size: 100% 100%;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    transition: all .5s;
    background-position: center center;


    .blog-name{
      font-size: $fontSizeL;
      display: table-cell;
      vertical-align: bottom;

      span{
        display:inline-block;
        width:100%;
        padding: 6px 0px;
        background-color:rgba(125,125,125,0.8);
        color:white;
      }
    }


  }
  .blog:hover{
    background-size: 120% 120%;
  }
  .controls{
    position:absolute;
    right:0;
    top:0;
    padding:5px;
  }

  .blog-item{
    background-color: $lightBlue;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 25px;
    cursor: pointer;
    position: relative;

    .blog-title{
      float: left;
      font-style: italic;
      margin-bottom:10px;
    }
    .blog-intro{
      clear:both;
    }
  }
}
/* end blogs */

/* make sidebar nav vertical */ 
@media (min-width: 768px) {

  .sidebar-nav .navbar ul {
    float: none;
  }
  .sidebar-nav .navbar ul:not {
    display: block;
  }
  .sidebar-nav .navbar li {
    float: none;
    display: block;
  }

}

/* end make sidebar nav vertical */


